/**
 * Add header details
 *
 */

function loadPageTabs(){

    //Click on a Tab
    clickTab();

    //center tab
    centerTabs();

    locatieGmap();


    //also on resize
    $( window ).resize(function() {
        centerTabs();
    });


}

//Center tab if it had "center" class
function centerTabs(){

    if( $("#page-tabs").hasClass("center") )
    {
        var totalItemWidth = 0;

        $.each($("#page-tabs").children(), function(tabItem){
            //totalItemWidth += $(this).outerWidth();
            totalItemWidth += $(this).width();
        });

        if(totalItemWidth >= 1)
        {
           totalWrapwidth = $("#page-tabs").width();
            var leftmargin = ((totalWrapwidth/2) - (totalItemWidth/2)) + "px";

            $("#page-tabs li:eq(0)").css("margin-left", leftmargin);
        }

    }
}

/* Click on a tab and do some nice things */
function clickTab(){

    $("#page-tabs li a,.open-tab").on("click", function(e) {

        //Set as active.
        var ID = $(this).attr("href");
        var navSelector = 'li a[href="' + ID + '"]';
        var li = $(navSelector).parent();

        if (! li.hasClass("active") )
        {
            //Switch tab
            $("#page-tabs li").removeClass("active");
            li.addClass("active");

            //Switch content
            $(".tab-content").removeClass("active");
            var ID = $(this).attr("href");
            $(ID).addClass("active");
        }

        //Scroll to tabs.
        scrollToTabs();

        if(ID == "#ligging")
        {
            locatieGmap();
        }



    });

}

/* Scroll to tab switch */
function scrollToTabs()
{

    //Display 2% over preview pageRow.
    var marge = ( window.innerHeight / 100) * 2;
    var tabPos = $('#page-tabs').offset().top;
    var headerHeight = 110;
    /*
    console.log(tabPos);
    console.log(headerHeight);
    */
    var scrollPos = tabPos - (headerHeight + marge);

    //console.log(scrollPos);


    $('html, body').animate({
        scrollTop: scrollPos
    }, 500);
    return false;


//    $('body').scrollTo('#page-tabs',{duration:'slow', offsetTop : '50'});

}


function locatieGmap()
{

    if( $("#gmap").length >= 1) {

        var long = $("#gmap").attr("data-long");
        var lat = $("#gmap").attr("data-lat");

        var map = new google.maps.Map(document.getElementById("gmap"), {
            zoom: 12,
            center: new google.maps.LatLng(lat, long),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false
        });

        var infowindow = new google.maps.InfoWindow();

        marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, long),
            icon: "/gfx/tmpl/gmap-icon.png",
            map: map
        });

    }
}


module.exports = loadPageTabs;