require("../vendor/magnific_popup/v0.9.9/magnific-popup-0.9.9.min");

function loadMagnificPopup(){

    $(document).ready(function() {


        $('.magnific-gallery').magnificPopup({
            delegate: 'a',
            /*type: 'image',*/
            tLoading: 'Laden...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1],  // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% / %total%'
            },
            image: {
                tError: '<a href="%url%">Afbeelding #%curr%</a> kan niet worden ingeladen.',
            }
        });

    });

}

module.exports = loadMagnificPopup;