/*require("../vendor/lightgallery/lightgallery.min");
require("../vendor/lightgallery/lg-thumbnail.min");
require("../vendor/lightgallery/lg-fullscreen.min");*/

//require("../vendor/bxslider/v4.1.2/jquery.bxslider.min");

require("../vendor/lightgallery-1.2.14/lightgallery");
require("../vendor/lightgallery-1.2.14/lg-thumbnail");
require("../vendor/lightgallery-1.2.14/lg-fullscreen");
require("../vendor/lightgallery-1.2.14/lg-video");


function loadLightGallery(){

    $(document).ready(function() {
        $('.locatie-photogallery').each(function(){
            $(this).lightGallery({
                download: false,
                counter: false,
                fullScreen: false,
            });
        });

    });

}

module.exports = loadLightGallery;