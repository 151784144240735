
function loadToplocaties(){

    loadMap();

    $('.popup-toplocaties').on('click', function (e) {
        e.preventDefault();

        $.magnificPopup.open({
            items: {
                src: $(this).attr('href'),
                type: 'inline'
            },

            mainClass: 'mfp-slide',
            removalDelay: 350
        });

        loadMap();
    });


}


function loadMap()
{

    if( $("#locatie-gmap").length >= 1) {

        var map = new google.maps.Map(document.getElementById("locatie-gmap"), {
            zoom: 10,
            center: new google.maps.LatLng(52.02984873785755, 5.911852684020996),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false
        });

        var infowindow = new google.maps.InfoWindow();

        $(".locatie-item").each(function(){

            var locItem = $(this);
            var long = $(this).attr("data-long");
            var lat = $(this).attr("data-lat");
            var ID = "#" + $(this).attr("id");

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, long),
                icon: "/gfx/tmpl/gmap-icon.png",
                map: map
            });

            var infowindow = new google.maps.InfoWindow({content: locItem.find(".map-content").html() });

            marker.addListener('click', function(){

                infowindow.open(map,marker);

                $(".locatie-item").removeClass("active");
                $(ID).addClass("active");
            });
        });

    }
}



module.exports = loadToplocaties;