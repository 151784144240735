//Require
window.$ = window.jQuery = require("./vendor/jquery/v1.11.3/jquery-1.11.3.min");
var modernizr = require("./src/modernizr");
var bootstrapValidator = require("./src/bootstrapvalidator");
var mobileMenu = require("./src/mobilemenu");
var bxSlider = require("./src/bxslider");
var loadHeader = require("./src/loadHeader");
var loadFotoInspiratie = require("./src/loadFotoInspiratie");
var loadPageTabs = require("./src/loadPageTabs");
var loadMagnificPopup = require("./src/magnificPopup");
var loadParalax = require("./src/paralax");
var loadOfferte = require("./src/offerte");
var loadToplocaties = require("./src/toplocaties");
var loadLightGallery = require("./src/lightgallery");
var loadLocatieImages = require("./src/locatieImages");
//var loadHTMLparts = require("./src/loadhtmlparts");

//Execute scripts
modernizr.checkSupportsvg();
modernizr.checkDevices();
mobileMenu();
bxSlider();
bootstrapValidator();
loadHeader();
loadPageTabs();
loadFotoInspiratie();
loadMagnificPopup();
loadParalax();
loadOfferte();
loadToplocaties();
loadLightGallery();
loadLocatieImages();
//loadHTMLparts();
