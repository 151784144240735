/**
 * Add header details
 *
 */

function loadFotoInspiratie(){

    changeFotoWidth();

    $( window ).resize(function() {
        changeFotoWidth();
    });

    //initFotoPopup();

}

function changeFotoWidth()
{
    if( $(".foto-impressie-list").length >= 1)
    {
        var numItems = 0;       //Number of total foto items
        var numTotalWidth = 0;  //total width of all textblocks
        var items = [];         //Array with calculations.
        var percentageWidth = 100;
        var margin = 0.25;       //margin between foto items
        //var marginTotal = 0;


        //Calculate width values
        $(".foto-impressie-list li").each(function(key){

            numItems++;

            items[key] = {};
            items[key]["width"]  = $(this).find(".title-inner").width();

            numTotalWidth += $(this).find(".title-inner").width();
        });

        //remove margins
        var totMargin = numItems * (margin * 2);
        percentageWidth = percentageWidth - totMargin;

        //Set percentages
        var percRest = percentageWidth;
        $(items).each(function(key){

            var itemPercentage =  items[key]["width"] / (numTotalWidth / 100);  //total percentage berekenen
            itemPercentage = Math.floor( itemPercentage );              // Afronden naar beneden
            itemPercentage = itemPercentage - (margin * 2 );         //Margin verwijderen.

            if (key == numItems - 1) {
                //percRest = percRest - (margin * 2 );
                items[key]["percentage"] = percRest;
            }else{
                items[key]["percentage"] = itemPercentage;
                percRest -= itemPercentage;
            }
        });

        //Set width to elements
        $(items).each(function(key){

            width = items[key]["percentage"] + "%";
            $(".foto-impressie-list li").eq(key).width(width);

        });

    }
}


function initFotoPopup()
{
    $('.fotoGalleryModal').each(function(){
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Afbeelding laden #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">Afbeelding #%curr%</a> kan niet worden ingeladen.'
            }
        });
    });

}


module.exports = loadFotoInspiratie;