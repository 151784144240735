
function loadOfferte(){


    $('.popup-form').on('click', function (e) {
        e.preventDefault();

        $.magnificPopup.open({
            items: {
                src: $(this).attr('href'),
                type: 'inline'
            },

            mainClass: 'mfp-slide',
            removalDelay: 350
        });
    });


    $("div.verzonden").hide();
    var offerteForm = $("#popup-form-offerte form");
    offerteForm.submit(
        function (e) {
            e.preventDefault();

            $(this).find('input:visible').change();

            var form = $(this);
            var error = false;

            $("input[required]:visible,textarea:visible", offerteForm).each(
                function()
                {
                    if ($(this).attr("type") == 'text' )
                    {
                        if ($(this).attr("id") == 'Telefoonnummer')
                        {
                            if (!validatePhone($(this).val()))
                            {
                                error = true;
                                $(this).addClass('error');
                            }
                            else
                            {
                                $(this).removeClass('error');
                            }
                        }
                        else
                        {
                            if ($(this).val() == '')
                            {
                                error = true;
                                $(this).addClass('error');
                            }
                            else
                            {
                                $(this).removeClass('error');
                            }
                        }
                    }
                    else if ($(this).attr("type") == 'email')
                    {
                        if ($(this).val() == '')
                        {
                            error = true;
                            $(this).addClass('error');
                        }
                        else if (!validateEmail($(this).val()))
                        {
                            error = true;
                            $(this).addClass('error');
                        }
                        else
                        {
                            $(this).removeClass('error');
                        }
                    }
                    else if ($(this).attr("type") == 'radio')
                    {
                        if ($("input[name="+$(this).attr("name")+"]:checked").length == 0)
                        {
                            error = true;
                            $(this).addClass('error');
                        }
                        else
                        {
                            $(this).removeClass('error');
                        }
                    }
                }
            );

            //console.log(error);
            if (error)
            {
                return true;
            }
            else
            {
                //console.log('Verzend');
                var visibleErrors = form.find('.ui-state-error:visible');
                //if (form.find('.error:visible').length === 0 && visibleErrors.length === 0) {
                if (visibleErrors.length === 0) {
                    $.post($(this).attr("action"),
                        $(this).serialize(),
                        function (data) {
                            form.parent().parent().find("div.verzonden").show();
                            form.parent().parent().find("div.form").hide();

                            if(data != null && $("#track_verzonden").length >= 1)
                            {
                                $("#track_verzonden").html(data);
                            }
                        }
                    );
                }
            }
        }

    );


    function validatePhone(phone) {
        var numbers = phone.replace(/\D/g,'');
        return numbers.length >= 10;
    }

    function validateEmail(email) {
        var patt = /\S+@\S+\.\S+/;
        return patt.test(email);    }

    function validatePostalCode(postalCode) {
        var patt = /[1-9]\d{3}\s*[A-z]{2}\b/;
        return patt.test(postalCode);
    }

    $('#Telefoonnummer').on('change', function (e) {
        if (validatePhone($(this).val())) {
            $(this).removeClass('error');
        } else {
            $(this).addClass('error');
        }
    });


    $('#Postcode').on('change', function(e) {
        if (validatePostalCode($(this).val())) {
            $(this).removeClass('error');
        }  else {
            $(this).addClass('error');
        }
    });


}

module.exports = loadOfferte;