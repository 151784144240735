/**
 * Add header details
 *
 */

function loadHeader(){

    // Shrinkage
    /*
    var headerMenu = $('#row-header');
    $(window).scroll(function() {
        if ($(document).scrollTop() > 64) {
            headerMenu.addClass('shrinked');
        } else {
            headerMenu.removeClass('shrinked');
        }
    }).scroll();
    */

    /*
    * Fill page row "header" so the new part will be shown
    */
    resizeHeaderRow();
    $(document).ready(function() {
        resizeHeaderRow();
    });


}

function resizeHeaderRow()
{
    if ($(".page-row-header").length <= 1) {

        if($(".page-row-header").hasClass("height-quarter") ||
           $(".page-row-header").hasClass("no-header") ){
            return false;
        }

        //Set row Height
        var pageHeight = window.innerHeight;

        //98% of total screen filled with header

        var pageRowHeight = (pageHeight / 100) * 98;


        //Minus tabsheight (if available)
        var tabHeight = 0;
        if ($("#page-row-tabs").length >= 1) {
            var tabHeight = $("#page-row-tabs").height();
        }

        //Calcultate total height
        var totalHeight = pageRowHeight - tabHeight;

        //Show 100% on small screens
        if($(window).width() <= 750) {
            var totalHeight = $( window ).height();
        }

        //Set Height
        $(".page-row-header").height(totalHeight);
    }
}

module.exports = loadHeader;