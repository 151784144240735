require("../vendor/bootstrapvalidator/v0.5.0/js/bootstrapValidator.min");
require("../vendor/bootstrapvalidator/v0.5.0/js/language/be_NL.js");

function formValidation() {

    var popup = $(".frm-validate").parents(".popup-wrap");
    if(typeof popup === 'undefined') {

        $(".frm-validate").bootstrapValidator({
            feedbackIcons: {
                valid: 'fa fa-check',
                invalid: 'fa fa-times',
                validating: 'fa fa-undo'
            },
            live: 'enabled',
            message: 'This field is required',
            submitButtons: '[type="submit"]',
            trigger: null,
            group: '.frm-group',
            errorElement: 'span',
            errorClass: 'alert'
        }).on('success.form.bv', function (e) {
            if (e.target.mm_bv_override) {
                e.preventDefault();
                e.target.mm_bv_override();
            }
        });
    }
}

module.exports = formValidation;
