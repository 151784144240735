require("../vendor/bxslider/v4.1.2/jquery.bxslider.min");

function bxSlider(){

    homeSlider();


}

function homeSlider()
{
    if ($("#bg-images li").length > 1) {
        $("#bg-images").bxSlider({
            auto: true,
            mode: 'fade',
            controls: false,
            captions: false



            /*

            autoControls: false,
            mode:'fade',
            pagerCustom: '.bx-pager',
            captions: false,
            controls: false,
            responsive: false,
            pause: 6000,
            preventDefaultSwipeY: false,
            touchEnabled: false,
            onSlideBefore: function(){
                $('.bx-clone').css('display', 'block');
            }
            */
        });
    }
    else {
        $("#bg-images").bxSlider({
            auto: false,
            autoControls: false,
            mode:'fade',
            captions: true,
            controls: false,
            responsive: true,
            pause: 6000,
            pager: false,
            preventDefaultSwipeY: false,
            touchEnabled: false,
            onSlideBefore: function(){
                $('.bx-clone').css('display', 'block');
            }
        });
        //$('.home-bg-images').css('display','none');
    }
}

module.exports = bxSlider;