require("../vendor/parallax/parallax");

function loadParalax(){

    $(document).ready(function() {
        //$('.paralax').imageScroll();
    });

}

module.exports = loadParalax;