require("../vendor/mmenu/v4.3.6/jquery.mmenu.min.all");

function doMobilemenu() {
        $(window).resize(function() {                       // This will fire each time the window is resized:
            if($(window).width() > 960) {                   // if screen resolution is greater than ..
                if ($('#mainnav-mobile').length > 0) {      // Check if a cloned navigation exists
                    $('#mainnav-mobile').remove();          // If true then remove cloned navigation
                }
                return false;
            }
            else {
                if ($('#mainnav-mobile').length <= 0) {     // Check if a cloned navigation exists
                    createMobilemenu();                     // Create mobile navigation
                }
            }

        }).resize();                                        // This will simulate a resize to trigger the initial run.
    }

    function createMobilemenu() {
        //$('header').addClass('mm-fixed-top');		    //Fixed header
        var $menu = $("#mainnav-wrapper").clone();		//Clone main navigation
        $menu.attr( "id", "mainnav-mobile" );			//Give cloned navigation ID
        $menu.mmenu({						            //Create mobile navigation
                //Options
                "classes": "mm-light",
                "counters": true,
                "offCanvas": {
                    "position": "right"
                },
                "header": {                             	//Add header title
                    "title": "Hoofdmenu",
                    "add": true,
                    "update": true
                }/*,
                "searchfield": {                        	//Add searchfield
                    "addTo": "panels",
                    "placeholder": "Zoeken",
                    "noResults": "Er zijn geen resultaten gevonden",
                    "add": true,
                    "search": true
                }*/
            }, {
                //Configuration
                "clone"  : false
            }
        );

        //Opening -- Add/remove classes to mobile button
        $menu.mmenu().on( "opening.mm", function() {
            $('.mobilenav-btn').addClass('mobilenav-opened');
            $('.mobilenav-btn').removeClass('mobilenav-closed');
        })

        //Closing -- Add/remove classes to mobile button
        $menu.mmenu().on( "closing.mm", function() {
            $('.mobilenav-btn').removeClass('mobilenav-opened');
            $('.mobilenav-btn').addClass('mobilenav-closed');
        })
        //Trigger to open/close mobile navigation on mobile button
        $('.mobilenav-btn').bind('click', function(e) {
            if ($(this).hasClass('mobilenav-opened')) {
                $("#mainnav-mobile").trigger('close');
                e.preventDefault();
            }
            else {
                $("#mainnav-mobile").trigger('open');
                e.preventDefault();
            }
        });
    }

module.exports = doMobilemenu;
