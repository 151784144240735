require("../vendor/modernizr/v2.7.1/modernizr.custom.min");

function checkDevices() {

        //This function adds classes to the HTML element with modernizr
        //If true 	--> modipad | modiphone | modipod | modios | etc.
        //If false 	--> modno-ipad | modno-iphone | modno-ipod | modno-ios | etc.

        Modernizr.addTest('android',function(){return!!navigator.userAgent.match(/Android/i)});
        Modernizr.addTest('chrome',function(){return!!navigator.userAgent.match(/Chrome/i)});
        Modernizr.addTest('firefox',function(){return!!navigator.userAgent.match(/Firefox/i)});
        Modernizr.addTest('iemobile',function(){return!!navigator.userAgent.match(/IEMobile/i)});
        Modernizr.addTest('ie',function(){return!!navigator.userAgent.match(/MSIE/i)});
        Modernizr.addTest('ie10',function(){return!!navigator.userAgent.match(/MSIE 10/i)});
        Modernizr.addTest('ios',function(){return!!navigator.userAgent.match(/iPhone|iPad|iPod/i)});
        Modernizr.addTest('mobile', function(){return RegExp("Mobile").test(navigator.userAgent);});
        Modernizr.addTest('webkit', function(){return RegExp("AppleWebKit").test(navigator.userAgent);});
        Modernizr.addTest('retina', function() {var dpr = window.devicePixelRatio||(window.screen.deviceXDPI / window.screen.logicalXDPI)||1;return !!(dpr > 1);});
        Modernizr.addTest('safari',function(){return!navigator.userAgent.match(/Chrome/i)&&!navigator.userAgent.match(/iPhone|iPad|iPod/i)&&!!navigator.userAgent.match(/Safari/i)});}

function checkSupportsvg() {
    if(!Modernizr.svg) {
        $('img[src*="svg"]').attr('src', function() {
            return $(this).attr('src').replace('.svg', '.png');
        });
    }
}

module.exports = {
    checkDevices:checkDevices,
    checkSupportsvg: checkSupportsvg
    };
